import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'
import { galleryContainer } from '../../styles/components/galleries.module.scss'
import '../../styles/components/swiper.css'
import { imgBox } from '../../styles/components/boxes.module.scss'
import { StaticImage } from 'gatsby-plugin-image'

const GallerySunset = () => {
  return (
    <div className={galleryContainer}>
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Party boat flags in Tampa Bay, FL at sunset."
              src="../../images/galleries/sunset-charter/party-boat-flags-in-tampa-bay-at-sunset.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Beautiful sunset in Tampa Bay, Florida."
              src="../../images/galleries/sunset-charter/beautiful-sunset-in-tampa-bay-florida.jpg"
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={imgBox}>
            <StaticImage
              alt="Sunset with tones of blue and gold near Tampa Bay, FL."
              src="../../images/galleries/sunset-charter/blue-and-gold-tampa-bay-sunset.jpg"
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default GallerySunset