import * as React from "react";
import { useState } from "react";
import LayoutService from "../../templates/LayoutService";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import BuySunsetBtn from "../../components/buttons/BuySunsetBtn";
import { Link } from "gatsby";
import "../../styles/components/titles.module.scss";
import "../../styles/components/header.module.scss";
import "../../styles/components/content.module.scss";
import "../../styles/components/contenttoggles.scss";
import { imgBox } from "../../styles/components/boxes.module.scss";
import {
  bannerBlock,
  bannerBlockHeadline,
  bannerBlockTagline,
  bannerBlockTitleSm,
  pageContentBlock,
  pageServiceDivider,
  serviceInfoBlock,
  serviceInfoBtns,
  pageContentMedia,
} from "../../styles/components/blocks.module.scss";
import { btn } from "../../styles/components/buttons.module.scss";
import { hideBlockSM } from "../../styles/components/helpers.module.scss";
import SunsetInfo from "../../components/blocks/SunsetInfo";
import GallerySunset from "../../components/galleries/GallerySunset";

const SunsetPage = () => {
  const [isActive, setActive] = useState(true);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <LayoutService>
      <Helmet>
        <title>
          Sunset Private Party Boat Charter | Tampa Bay Boating Adventures
        </title>
        <meta
          name="description"
          content="Take in a famous Florida sunset with your own private party boat charter and relax on our 3-hour sunset party boat charter package. Book online today!"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className="container">
        <div className="row">
          <div className="col-xxs-12">
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="Beautiful sunset on Tampa Bay, FL coast."
                  src="../../images/banners/gorgeous-sunset-on-tampa-bay-coast.jpg"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>
                  Sunset Party Boat Charter in Tampa Bay
                </h1>
                <span className={bannerBlockTitleSm}>
                  Relax with <br className={hideBlockSM} />a Tampa Sunset
                </span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className="container">
        <div className="row">
          <div className="col-xxs-12">
            <GallerySunset />
            <div className={serviceInfoBlock}>
              <h2>Sunset Party Boat Package Summary</h2>
              <div
                className={serviceInfoBtns}
                style={{
                  display: "flex",
                  textAlign: "center",
                
                  justifyContent: "flex-start",
                }}
              >
                <BuySunsetBtn />
                <Link
                  to="tel:8137428178"
                  className={`${btn}`}
                  style={{ marginRight: "10px", background: "#0DB0E7" }}
                >
                  Call (813) 742-8178
                </Link>

                {/* <a href="#description" className={btn} onClick={toggleClass}>{isActive ? "Hide Description" : "View Description"}</a> */}
              </div>

              <SunsetInfo />
            </div>
            <div
              id="pageContentContainer"
              className={isActive ? "pageContentOpen" : "noClass"}
            >
              <div className="anchor" id="description"></div>
              <div className={pageContentBlock}>
                <hr className={pageServiceDivider} />
                <h2>
                  Our sunset party boat charter is the perfect way to end the
                  day.
                </h2>
                <h3>
                  Party the evening away with your friends on our sunset party
                  boat charter.
                </h3>
                <div className={pageContentMedia}>
                  <div className={imgBox}>
                    <StaticImage
                      alt="Golden sunset in Tampa Bay, Florida."
                      src="../../images/banners/early-sunset-in-tampa-bay-florida.jpg"
                    />
                  </div>
                </div>
                <p>
                  Great music, incredible views, beautiful marine life, cold
                  drinks, breathtaking sunset: that’s what you can expect on our
                  sunset party boat charter. At Tampa Bay Boating Adventures, we
                  offer sunset cruises with a twist. While we do want you to
                  experience an incredible sunset, we also want you to have a
                  blast with your friends in the process. Our sunset cruise is
                  BYOB, so you can bring any alcohol you want to turn your trip
                  into your own private booze cruise. While we’re cruising the
                  Tampa Bay waters, we’ll pass picturesque properties, and
                  likely see an abundance of marine life, including dolphins!
                  Fill out our submission form now or send us an email to book
                  our private sunset party boat charter today!
                </p>
                <div
                  className={serviceInfoBtns}
                  style={{
                    display: "flex",
                    textAlign: "center",
                   
                    justifyContent: "flex-start",
                  }}
                >
                  <BuySunsetBtn />
                  <Link
                    to="tel:8137428178"
                    className={`${btn}`}
                    style={{ marginRight: "10px", background: "#0DB0E7" }}
                  >
                    Call (813) 742-8178
                  </Link>
                </div>
                <hr />
                <h2>
                  Crank up the music and crack open a cold one on our sunset
                  cruise!
                </h2>
                <p>
                  This isn’t your grandma’s sunset cruise. We want you to have a
                  blast and party the evening away. You can bring any alcoholic
                  beverages you want on our sunset cruise to help get the party
                  started. Please just drink responsibly and of course, you must
                  be at least 21 years of age or older. Music is also a major
                  part of our sunset cruise. Our captain will have a curated
                  playlist of every genre ready for you to choose from. However,
                  if you want to play your own music, just send us a playlist on
                  Spotify and we’ll play it during the cruise. We can crank the
                  music up loud if you want to jam out, or turn it down a little
                  if you want to talk, just let us know your preference.
                </p>
                <hr />
                <h2>
                  We’ll cruise to the perfect spot so you can see an incredible
                  sunset.
                </h2>
                <p>
                  The sun sets in Tampa Bay at different times depending on the
                  season. Our experienced captains are intimately familiar with
                  the Tampa Bay area and the patterns of the sunset. We want to
                  make sure you and your friends get the best possible view of
                  the sunset, so we’ll take you to the perfect spot depending on
                  the season.
                </p>
                <div
                  className={serviceInfoBtns}
                  style={{
                    display: "flex",
                    textAlign: "center",
                   
                    justifyContent: "flex-start",
                  }}
                >
                  <BuySunsetBtn />
                  <Link
                    to="tel:8137428178"
                    className={`${btn}`}
                    style={{ marginRight: "10px", background: "#0DB0E7" }}
                  >
                    Call (813) 742-8178
                  </Link>
                </div>
                <hr />
                <h2>
                  You’ll likely see an abundance of marine life on our sunset
                  cruise.
                </h2>
                <p>
                  More than likely, the sunset won’t be the only thing you see
                  on our sunset cruise. The Tampa Bay waters are filled with an
                  abundance of sea life, and oftentimes, we get to see some of
                  these animals up close while on our cruise. We often see pods
                  of dolphins who love following boats and jumping in the wake
                  they leave behind. We also frequently spot sea turtles, sting
                  rays, manatees, schools of fish, and many different species of
                  birds!
                </p>
                <hr />
                <h2>
                  Fill out our submission form to sign up for our private sunset
                  party boat charter today!
                </h2>
                <p>
                  Get off the sand and out onto the water to see an incredible
                  sunset in a unique and fun way! With loud music, cold drinks,
                  and breathtaking views, you and your friends will be sure to
                  have a great time! Just{" "}
                  <Link to="/contact">fill out our submission form</Link> or{" "}
                  <a href="mailto:ahoy@tampabayboatingadventures.com">
                    send us an email
                  </a>{" "}
                  to book your spot on our private sunset party boat charter
                  today. Just make sure you do it ASAP! Our calendar fills up
                  quickly, so you’ll want to book as soon as you can to
                  guarantee your reservation on the evening you want!
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  );
};

export default SunsetPage;
